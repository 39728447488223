import React, { useState } from 'react';
import context from './context.js';
const host = "https://backendalltwo-production.up.railway.app"

const State = (props) => {
  const [leads, setleads] = useState([])
  const login = async (data) => {
    const response = await fetch(`${host}/login`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if (json.success) {
      localStorage.setItem('admin2194-secret-login-token', json.token)
      window.location.reload()
    } else {
      alert(json.message)
    }
  }


  // Loads
  const getallLeads = async () => {

    const response = await fetch(`${host}/contactData`, {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json",
        "Authentication": localStorage.getItem('admin2194-secret-login-token')
      }
    })
    const json = await response.json();
    if (json.success) {
      setleads(json.data)
    } else {
      alert(json.message)
    }
  }



  return (
    <context.Provider value={{ login, getallLeads, leads }}>
      {props.children}
    </context.Provider>
  )

}

export default State