import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar.jsx';
import Login from './components/login.jsx';
import State from './context/state.js';
import Blogs from './components/ContactData.jsx';

const App = () => {
  const [loggedin, setLoggedin] = useState(false);

  useEffect(() => {
    const secretLoginToken = localStorage.getItem('admin2194-secret-login-token');
    setLoggedin(secretLoginToken !== null);
    var result = secretLoginToken !== null;
  }, []);

  return (
    <div className="appbody">
      <State>
        <Router>

          {!loggedin ? (
            <Routes>
              <Route path="*" element={<Login />}/>
            </Routes>

          ) : (
            <>
              <Navbar />
            <Routes>
              <Route path="/" element={<Blogs />} />
            </Routes>
              </>
          )}


        </Router>
      </State>
    </div>
  );
};

export default App;

