import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import csvDownload from 'json-to-csv-export'
import context from '../context/context';

const ContactData = () => {
    const a = useContext(context);
    const getallLeads = a.getallLeads;
    const leads = a.leads;

    useEffect(() => {
        getallLeads();
    }, []);

    const Exportjsontocsv = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        const headers = "FirstName;LastName;Phone;Email;Amount;Time;ROI;Region\n";
    
        csvContent += headers;
    
        leads.forEach(lead => {
            const row = `${lead.firstname};${lead.lastname};${lead.Phone};${lead.email};${lead.amount};${lead.primary_reason};${lead.postcode}\n`;
            csvContent += row;
        });
    
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'leads.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    

    return (
        <div className='main-data'>
            <h1 className="my-3 text-center">All Leads</h1>
            <Button className="mb-3" onClick={Exportjsontocsv}>
                Export in CSV
            </Button>
            <div className="table-responsive">
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contact Details</th>
                            <th>Investment Amount</th>
                            <th>primary Reason</th>
                            <th>Post Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leads.map((lead) => (
                            <tr key={lead._id}>
                                <td>{lead.firstname} {lead.lastname}</td>
                                <td>{lead.Phone} <br />{lead.email}</td>
                                <td>{lead.amount}</td>
                                <td>{lead.primary_reason}</td>
                                <td>{lead.postcode}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default ContactData;
